import React, { Component } from 'react';
import { graphql, ChildProps } from 'react-apollo';
import { gql } from 'apollo-boost';
import ThreeContainer from './ThreeContainer';
import { Drawing } from '@hi-level/3d-renderer';

const QUOTE_OPTION_QUERY = gql`
  query GetDrawing($uuid: ID!) {
    quoteOptionByUuid(uuid: $uuid) {
      drawing {
        Floor {
          Size {
            XDim
            YDim
            TotalZDim
          }
          Tiers {
            Number
            Size {
              XDim
              YDim
              ZDim
            }
            Columns {
              Id
              Position {
                X
                Y
              }
              IsFlagpost
              IsGoalpost
              IsTagged
              Connections {
                Capplates {
                  Id
                }
                Beams {
                  Id
                }
              }
            }
            Beams {
              Id
              ConstructionType
              Start {
                X
                Y
              }
              End {
                X
                Y
              }
            }
            Joists {
              Start {
                X
                Y
              }
              End {
                X
                Y
              }
              Connections {
                Beams {
                  Id
                }
                Columns {
                  Id
                }
              }
            }
            Angles {
              CutoutDirection
              Position {
                X
                Y
              }
              Start {
                X
                Y
              }
              End {
                X
                Y
              }
            }
            Cutouts {
              Size {
                XDim
                YDim
              }
              Position {
                X
                Y
              }
            }
          }
          BayDirection
        }
      }
    }
  }
`;

type Response = {
  quoteOptionByUuid: {
    drawing: Drawing;
  };
};

type InputProps = {
  uuid: string;
};

type Variables = {
  uuid: string;
};

type ChildDataProps = ChildProps<InputProps, Response, Variables>;

const withDrawing = graphql<InputProps, Response, Variables, ChildDataProps>(
  QUOTE_OPTION_QUERY,
  {
    options: ({ uuid }) => ({
      variables: { uuid },
    }),
  }
);

class DrawingContainer extends Component<ChildDataProps, {}> {
  render() {
    if (!this.props.data) return <h1>ERROR</h1>;
    const { loading, quoteOptionByUuid, error } = this.props.data;
    if (loading) return <div>Loading</div>;
    if (error || !quoteOptionByUuid) return <h1>ERROR</h1>;
    return (
      <ThreeContainer
        drawing={quoteOptionByUuid.drawing}
        uuid={this.props.uuid}
      />
    );
  }
}

export default withDrawing(DrawingContainer);
