import React from 'react';
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
  Link,
} from 'react-router-dom';
import ApolloClient from 'apollo-boost';
import { ApolloProvider } from 'react-apollo';
import { Container, Navbar } from 'react-bootstrap';
import { format } from 'date-fns';
import { version } from '../package.json';
import './App.css';
import logo from './hi-level_logo.png';
import Home from './containers/Home';
import Viewer from './containers/Viewer';
import { withTracker } from './withTracker';

const client = new ApolloClient({
  uri: process.env.REACT_APP_API_URL,
  name: '3D Viewer',
  version,
});

const App: React.FC = () => {
  return (
    <ApolloProvider client={client}>
      <Router>
        <div className='App'>
          <Navbar bg='light' expand='lg' sticky='top'>
            <Navbar.Brand as={Link} to='/'>
              <img
                alt='Hi-Level Logo'
                src={logo}
                height='30'
                className='d-inline-block align-top'
              />{' '}
              iQD 3D Viewer
            </Navbar.Brand>
          </Navbar>
          <Container fluid>
            <Switch>
              <Route path='/' exact component={withTracker(Home)} />
              <Route path='/:uuid' exact component={withTracker(Viewer)} />
              <Redirect to='/' />
            </Switch>
          </Container>
        </div>
        <footer className='footer fixed-bottom'>
          <small className='text-muted'>
            &copy; 2016 - {format(new Date(), 'yyyy')}, The Mezzanine Floor
            Group Ltd. All rights reserved.{' '}
            <a
              href='https://www.hi-levelmezzanines.co.uk/privacy-policy/'
              target='_blank'
              rel='noopener noreferrer'
            >
              Privacy Policy
            </a>
          </small>
        </footer>
      </Router>
    </ApolloProvider>
  );
};

export default App;
