import React, { Component } from 'react';
import { Button, Row, Col, Card } from 'react-bootstrap';
import { Renderer, Drawing } from '@hi-level/3d-renderer';
import './ThreeContainer.css';
import CallMeModal from './CallMeModal';
import ShareModal from './ShareModal';

type InputProps = {
  drawing: Drawing;
  uuid: string;
};

export default class ThreeContainer extends Component<
  InputProps,
  { buttonsEnabled: boolean }
> {
  private el: any = null;
  private renderer: any = null;

  constructor(props: InputProps) {
    super(props);
    this.state = {
      buttonsEnabled: false,
    };
  }

  componentDidMount() {
    this.renderer = new Renderer({
      containerElement: this.el,
      baseUrl: `${process.env.PUBLIC_URL}/models/`,
      onError: this.onRendererError,
    });
    if (this.renderer.canRender) {
      this.renderer.loadFloor(this.props.drawing);
      this.setState({ buttonsEnabled: true });
    }
  }

  onRendererError = (error: ErrorEvent) => {
    throw error;
  };

  toggleDeckingClick = () => {
    this.renderer.toggleDecking();
  };

  takeSnapshotClick = () => {
    const imageData = this.renderer
      .getImageData()
      .replace('image/jpeg', 'image/octet-stream');
    this.saveFile('floor.jpg', imageData);
  };

  saveFile(filename: string, strData: string) {
    try {
      const link = document.createElement('a');
      document.body.appendChild(link); // Firefox requires the link to be in the body
      link.download = filename;
      link.href = strData;
      link.click();
      document.body.removeChild(link); // remove the link when done
    } catch (error) {
      console.log(error);
    }
  }

  render() {
    return (
      <Row>
        <Col>
          <Card>
            <Card.Header>
              <Button
                variant='primary'
                onClick={this.toggleDeckingClick}
                disabled={!this.state.buttonsEnabled}
              >
                Toggle decking
              </Button>{' '}
              <Button
                variant='primary'
                onClick={this.takeSnapshotClick}
                disabled={!this.state.buttonsEnabled}
              >
                Take snapshot
              </Button>{' '}
              <CallMeModal /> <ShareModal uuid={this.props.uuid} />
            </Card.Header>
            <Card.Body>
              <div
                ref={(element) => (this.el = element)}
                className='Renderer'
              />
            </Card.Body>
          </Card>
        </Col>
      </Row>
    );
  }
}
