import React, { Component } from 'react';
import { Button, Modal, Form } from 'react-bootstrap';
import { Formik, FormikActions } from 'formik';
import * as yup from 'yup';
import PhoneNumber from 'awesome-phonenumber';
import { gql } from 'apollo-boost';
import { Mutation } from 'react-apollo';

type InputProps = {};

interface FormValues {
  phoneNumber: string;
}

const schema = yup.object({
  phoneNumber: yup
    .string()
    .test('is-valid-phone-number', 'Phone number is not valid', (value) =>
      new PhoneNumber(value ? value : '', 'GB').isValid()
    )
    .required('Required'),
});

const CALL_SALES = gql`
  mutation callSales($callSalesInput: CallSalesInput!) {
    callSales(callSalesInput: $callSalesInput)
  }
`;

export default class CallMeModal extends Component<
  InputProps,
  { show: boolean }
> {
  constructor(props: InputProps) {
    super(props);
    this.state = {
      show: false,
    };
  }

  handleShow = () => {
    this.setState({ show: true });
  };

  handleClose = () => {
    this.setState({ show: false });
  };

  render() {
    return (
      <>
        <Button variant='primary' onClick={this.handleShow}>
          Call me
        </Button>

        <Modal show={this.state.show} onHide={this.handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Call me</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Enter your phone number and we will call you straight away.
            <hr />
            <Mutation mutation={CALL_SALES}>
              {(callSales: Function, { data }: any) => (
                <Formik
                  validationSchema={schema}
                  onSubmit={(
                    values: FormValues,
                    actions: FormikActions<FormValues>
                  ) => {
                    callSales({
                      variables: {
                        callSalesInput: values,
                      },
                    });
                    actions.setSubmitting(false);
                  }}
                  initialValues={{
                    phoneNumber: '',
                  }}
                >
                  {({
                    handleSubmit,
                    handleChange,
                    values,
                    touched,
                    isValid,
                    errors,
                  }) => (
                    <Form noValidate onSubmit={handleSubmit}>
                      <Form.Group controlId='phoneInput'>
                        <Form.Label>Phone number</Form.Label>
                        <Form.Control
                          type='text'
                          name='phoneNumber'
                          value={values.phoneNumber}
                          onChange={handleChange}
                          isValid={touched.phoneNumber && !errors.phoneNumber}
                        />
                        <Form.Control.Feedback>
                          Looks good!
                        </Form.Control.Feedback>
                      </Form.Group>
                      <Button type='submit' disabled={!isValid}>
                        Call
                      </Button>
                    </Form>
                  )}
                </Formik>
              )}
            </Mutation>
          </Modal.Body>
        </Modal>
      </>
    );
  }
}
