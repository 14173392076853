import React, { Component } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import DrawingContainer from '../components/DrawingContainer';
import BetaAlert from '../components/BetaAlert';

export default class Viewer extends Component<
  RouteComponentProps<{ uuid: string }>
> {
  render() {
    const { uuid } = this.props.match.params;
    return (
      <>
        <BetaAlert />
        <DrawingContainer uuid={uuid} />
      </>
    );
  }
}
