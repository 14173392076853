import React, { Component } from 'react';
import { Alert, Modal, Col, Row, Figure } from 'react-bootstrap';
import SbImg from './strut_brace.jpg';

type InputProps = {};

export default class BetaAlert extends Component<
  InputProps,
  { show: boolean; showModal: boolean }
> {
  constructor(props: InputProps) {
    super(props);
    this.state = {
      show: true,
      showModal: false,
    };
  }

  bracingClick = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    e.preventDefault();
    this.setState({ showModal: true });
  };

  render() {
    if (this.state.show) {
      return (
        <>
          <Alert
            variant='info'
            onClose={() => this.setState({ show: false })}
            dismissible
          >
            This is <strong>beta</strong> software. It is designed to
            demonstrate the mezzanine layout and does not include any
            ancillaries or{' '}
            <a href='#/' onClick={this.bracingClick}>
              bracing
            </a>{' '}
            (required).
          </Alert>

          <Modal
            show={this.state.showModal}
            onHide={() => this.setState({ showModal: false })}
          >
            <Modal.Header closeButton>
              <Modal.Title>Bracing</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Row>
                <Col>
                  <p>
                    Some form of bracing will be required on a mezzanine floor,
                    generally strut or cross braces. This 3D viewer does not
                    currently show bracing. To discuss how this affects the
                    final design, click the 'call me' button to speak to our
                    sales team.
                  </p>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Figure>
                    <Figure.Image
                      width='100%'
                      alt='Bracing example'
                      src={SbImg}
                    />
                    <Figure.Caption>
                      Example of strut bracing on a mezzanine floor
                    </Figure.Caption>
                  </Figure>
                </Col>
              </Row>
            </Modal.Body>
          </Modal>
        </>
      );
    }
    return null;
  }
}
